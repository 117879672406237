import TopUtil,{IPageAnimation,IRouteAnimation,IDialogAnimation,ITopDataObj,IAccessedModule} from "@/components/layout/ts/top/topUtil";
import {reactive, toRefs, onMounted, onBeforeMount, getCurrentInstance, nextTick, defineComponent, onActivated,inject} from 'vue';
import config from "@/utils/config";
import user from '@/assets/user.jpg';
import main_logo from '@/assets/main_logo1.png';
export default defineComponent({
    name: "Top",
    setup(){
        let mainComp=inject('mainComp') as any;
        const {proxy} = getCurrentInstance() as any;
        const utils=proxy.utils; 
        let pageAnimation:IPageAnimation={//页面动画
            selItems:[],//已启用的动画（在onMounted中会重新判断赋值）
            items:[
                {id:'loginAnimation',value:proxy.$t('top.loginAnimation')},
                {id:'mainAnimation',value:proxy.$t('top.mainAnimation')},
                {id:'listAnimation',value:proxy.$t('top.listAnimation')},
                {id:'formAnimation',value:proxy.$t('top.formAnimation')}
            ]
        }
        let routeAnimation:IRouteAnimation={//路由动画
            defRouteAnimation:!localStorage.getItem('routeAnimation')?'fade-transform':localStorage.getItem('routeAnimation') as string,
            options:[
                {value:'el-zoom-in-center',label: 'el-zoom-in-center'},
                {value:'el-fade-in',label: 'el-fade-in'},
                {value:'fade-transform',label: 'fade-transform'}
            ]
        }
        let dialogAnimation:IDialogAnimation={//弹出框动画设置（背景图以及弹出框打开/关闭动画）
            isActiveBg:utils.Tools.isAnimationOpen('dialogBgAnimation'),//是否启用弹出框背景图片
            backPicPath:!localStorage.getItem('backPicPath')?'_dynamic_':utils.UtilPub.decrypt(localStorage.getItem('backPicPath')),
            imageSource:[],//手动设置弹出框背景图片
            isActive:utils.Tools.isAnimationOpen('dialogAnimation')//是否启用弹出框内容动画
        }

        let accessedModule:IAccessedModule={
            isVisible:false,
            moduleArr:[]
        }
        const dataObj:ITopDataObj=reactive<ITopDataObj>({
            utilInst: {} as any,
            refMap:new Map(),
            systemTitle:config.title,
            searchContent:'',//搜索菜单的关键字
            isSearch:false,//显示搜索图表还是显示搜索框
            userInfo:sessionStorage.getItem('userInfo')?JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem('userInfo'))):'',
            userMenus:sessionStorage.getItem('userMenus')?JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem('userMenus'))):'',
            //是否具有接收消息的权限
            hasMessageReceivePermission:utils.Tools.getPropFromVuex('hasMessageReceivePermission'),
            //是否具有修改个人信息的权限
            hasChangePersonalInfosPermission:utils.Tools.getPropFromVuex('hasChangePersonalInfosPermission'),
            isTopMenu: true,//是否显示顶部菜单（在onMounted中会重新判断赋值）
            activeTopMenu: '',//当前顶部选中的菜单
            topMenu: [],//顶部菜单集合（其实就是所有的第一级菜单）
            notQsNum: '',//没有签收的消息数量
            pageAnimation: pageAnimation,//页面动画配置
            routeAnimation:routeAnimation,//路由动画
            dialogAnimation:dialogAnimation,//弹出框动画设置（背景图以及弹出框打开/关闭动画）
            themes: {//主页风格设置
                curTheme:!localStorage.getItem('curTheme')?'defaultTheme':localStorage.getItem('curTheme') as string,
                themeArray:[{label:proxy.$t('top.defaultTheme'),value:'defaultTheme'},{label:proxy.$t('top.darkTheme'),value:'darkTheme'},{label:proxy.$t('top.lightBlueTheme'),value:'lightBlueTheme'}]
            },
            avatarImage: user,//用户头像图片路径
            logoPng: main_logo,//logo图片的路径
            accessedModule:accessedModule,//已访问模块
            flag1:config.flag1,
            otherParams:{
                isVisible:false,//个性化设置drawer打开关闭开关
            }
        });
        onActivated(()=>{
            //在onActivated里面要改变userMenus的值，否则在layer那里改变了userMenus，在这里还是之前的，因为两次通过layer进入top，
            //在top这里不会每次都触发onMounted，但只要top显示一次，就会调用一次onActivated
            if(sessionStorage.getItem("slideUserMenus")){
                dataObj.userMenus = JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("slideUserMenus")));
            }else{
                dataObj.userMenus = JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("userMenus")));
            }
            nextTick(async ()=>{
                dataObj.utilInst.initTopMenu(mainComp);//如果是顶部菜单模式，则初始化顶部菜单
            })
            if(sessionStorage.getItem("accessModule")){//本次从layer点击的菜单
                let accessModule:any= JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("accessModule")));
                //检查本地存储的已访问菜单中是否有本次从layer点击的菜单，没有则加入，有则不管
                let index=dataObj.accessedModule.moduleArr.findIndex((item:any)=>{
                    return item.id==accessModule.id;
                })
                if(index==-1)dataObj.accessedModule.moduleArr.push(accessModule);
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TopUtil(proxy,dataObj);
        })
        onMounted(async ()=>{
            nextTick(async ()=>{
                await dataObj.utilInst.queryNotQsMsgNum(); //加载未签收的消息数量
                dataObj.utilInst.initTopMenu(mainComp);//如果是顶部菜单模式，则初始化顶部菜单
                dataObj.utilInst.initPageAnimationConfig();//初始化页面动画已经设置过的项
                dataObj.utilInst.dialogBgConfig();//初始化弹出框可以设置哪些背景图片
                if(sessionStorage.getItem('systemTitle'))dataObj.systemTitle=sessionStorage.getItem('systemTitle') as string;
            })
        })
        //根据当前访问的路由找到该路由的顶级菜单id
        const findMenu=(userMenus:any,menuPath:string)=>{
            //可谓是歪打正着，本来最开始想找访问路由的id，结果用find的方式找到的是该路由的顶层菜单id，
            //我结果就用for循环来找访问的路由id，发现该id根本就不是顶层菜单的id，顶层菜单id一定是第一级菜单，
            //这里是为了根据访问的路由来高亮顶层菜单
            return userMenus.find((item:any)=> {
                if(item.children){
                    let result=findMenu(item.children,menuPath);
                    if(result) return result;
                }else{
                    if(item.path==menuPath)return item;
                }
            });
        }
        //在tabs.vue调用（通过搜索的方式跳转到对应路由，会在tabs.vue那里被watch监测到，然后把该路由的顶部菜单高亮显示）
        //不能移到其它地方，因为某些组件调用的时候，是调用的该组件的这个方法，移动到其它地方去之后就找不到了
        const doActiveTopMenu=(options:any)=>{
            if (dataObj.isTopMenu){
                let item=findMenu(dataObj.userMenus,proxy.$router.currentRoute.value.path);
                if(item)dataObj.activeTopMenu=item.id;
            }
        }
        //已访问模块点击事件，切换访问模块
        const accessedModuleClick=(item:any)=>{
            //更新top中的菜单，万一是横向菜单展示的话就有用了
            if(item.children){
                dataObj.userMenus=item.children;
            }else{
                dataObj.userMenus=[item];
            }
            //非常奇怪，当访问了几个模块之后，如果有全屏展示的，有不是全屏展示的，这个时候，再到快捷访问这里切换的时候，全部都变成了全屏展示的情况
            //所以下方需要手动改变主页的prop值，这样就可以实现不是全屏展示的就不需要全屏展示了
            if(!item.children && item.meta.prop==2){//如果是末级节点且该节点是铺满整个content，则直接跳转
                mainComp.prop=2;
                proxy.$router.push(item.path);
            }else{ //如果不是末级节点，则看当前情况下是否有展示左侧栏，如果没有展示，则slideBarRef为undefined，所以需要判断一下
                mainComp.prop=0;
                nextTick(()=>{
                    mainComp.slideBarRef.changeMenu(dataObj.userMenus);//更新slideBar中的菜单，也就是左侧的菜单
                })
            }
            // dataObj.accessedModule.isVisible=false;
        } 
        return{
            ...toRefs(dataObj),mainComp,doActiveTopMenu,accessedModuleClick
        }
    }
})